.item-box {
  position: relative; /* Position relative to enable absolute positioning of the hovered image */
  background-color: white;
  color: black;
  padding: 15px; /* Consistent padding */
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  border: 1px solid #ccc; /* Lighter border */
  width: calc(25% - 20px); /* Adjust width for 4 boxes per row with gaps */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content inside the item box */
  /* Add min-height to give it a bit more height but still keep flexibility */
  min-height: 200px; /* Adjust the value as per your design */
}

.item-page-image-container {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center the image inside the box */
}

.item-page-image {
  /* width: 65%; */
  width: 170px;
  margin-bottom: 20px;
  height: 170px; /* Fixed height for all images */
  /* border-radius: 8px; Rounded corners */
}

.item-box:hover {
  background-color: #7a9a9a; /* Blue agave color on hover */
  border-color: #7a9a9a; /* Border color changes on hover */
}

/* Pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-animation {
  animation: pulse 0.6s ease-in-out;
}

.favorite-icon {
  position: absolute;
  top: 10px; /* Adjust as necessary */
  right: 10px; /* Adjust as necessary */
  cursor: pointer; /* Show a pointer cursor on hover */
}

.not-clickable {
  opacity: 0.6; /* Dim the box */
  pointer-events: none; /* Disable clicking */
}

.not-clickable .favorite-icon {
  opacity: 5; /* Dim the box */
  pointer-events: auto; /* Ensure it remains clickable */
  cursor: pointer;
  color: black; /* Optional: darker color to make it stand out */
}

/* iPhone SE, 6/7/8, X and newer (320px to 414px) */
@media only screen and (min-device-width: 320px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .item-box {
    width: calc(50% - 20px);
    margin-bottom: 15px; /* Space between the rows */
  }
}

/* Larger mobile screens (414px to 896px) */
@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3) {
  .item-box {
    width: calc(50% - 20px);
    margin-bottom: 20px; /* Space between the rows */
  }
}
