@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Montserrat:wght@400;700&family=Roboto:wght@400;700&family=Lato:wght@400;700&display=swap");

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  /* font-family: "Lato", sans-serif; */
  /* font-family: "Inter"; */
  /* font-family: "Roboto"; */
  background-image: linear-gradient(white, #d0e7e3);
}

button {
  background-color: #a4b6b6; /* Blue Agave color */
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 10px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for background color */
  margin-right: 15px;
  margin-left: 10px;
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
}

body {
  display: block;
  margin: 0;
}

.main-content {
  padding-top: 130px; /* Adjust based on the actual height of your header */
  padding-bottom: 50px; /* Space above the footer */
}
