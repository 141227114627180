/* src/pages/faq.css */
.faq-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.faq-item {
  border-top: 2px solid #333;
  padding: 20px 0;
}

.faq-item h2 {
  color: #333;
}

.faq-item p {
  margin-top: 10px;
  line-height: 1.6;
}
