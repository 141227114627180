.cart-update-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cart-update-modal-content {
  background: white;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.cart-update-modal-content h2 {
  margin-top: 0;
  color: #333;
}

.issues-section,
.discount-section {
  margin-bottom: 20px;
}

.issues-section ul,
.discount-section ul {
  list-style-type: none;
  padding: 0;
}

.issues-section li,
.discount-section li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-item-btn {
  background: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.remove-item-btn:hover {
  background: #c0392b;
}

.modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.close-modal-btn {
  background: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}

.close-modal-btn:hover {
  background: #2980b9;
}

.close-modal-btn:disabled {
  background: #bdc3c7;
  cursor: not-allowed;
}
