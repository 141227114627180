/* UserDashboardPage.css */
.user-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%; /* Full width */
}

.profile {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.greeting {
  margin-bottom: 10px;
}

.sidebar {
  position: fixed; /* Keeps sidebar fixed on scroll */
  left: 0;
  width: 200px;
  height: calc(100vh - 60px); /* Full height minus header */
  padding: 10px;
  box-sizing: border-box;
}

.user-main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.sidebar li.active {
  background-color: #f0f0f0;
}

/* iPhone SE, 6/7/8, X and newer (320px to 414px) */
@media only screen and (min-device-width: 320px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .user-dashboard {
    flex-direction: column; /* Stack everything vertically */
  }

  .sidebar {
    position: static; /* Make sidebar part of normal flow */
    width: 100%;
    height: auto;
    padding: 10px 0;
  }

  .sidebar ul {
    display: flex; /* Make the sidebar tabs horizontal */
    justify-content: space-around;
  }

  .sidebar li {
    flex-grow: 1; /* Make each tab take equal space */
    border-bottom: none; /* Remove bottom border */
    border-right: 1px solid #ccc; /* Add right border between tabs */
  }

  .sidebar li:last-child {
    border-right: none; /* Remove right border for the last tab */
  }

  .user-main-content {
    margin-top: 20px; /* Add space between tabs and content */
  }

  .profile {
    margin-top: 20px;
    text-align: center;
  }
}

/* iPhone larger screens (414px to 896px) */
@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3) {
  .user-dashboard {
    flex-direction: column; /* Stack everything vertically */
  }

  .sidebar {
    position: static; /* Make sidebar part of normal flow */
    width: 100%;
    height: auto;
    padding: 10px 0;
  }

  .sidebar ul {
    display: flex; /* Make the sidebar tabs horizontal */
    justify-content: space-around;
  }

  .sidebar li {
    flex-grow: 1; /* Make each tab take equal space */
    border-bottom: none; /* Remove bottom border */
    border-right: 1px solid #ccc; /* Add right border between tabs */
  }

  .sidebar li:last-child {
    border-right: none; /* Remove right border for the last tab */
  }

  .user-main-content {
    margin-top: 20px; /* Add space between tabs and content */
  }

  .profile {
    margin-top: 20px;
    text-align: center;
  }
}
