.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-redirect {
  background-color: #3e5455;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-redirect:hover {
  background-color: #367c2b;
}

.btn-qt-cancel {
  background-color: #7a9a9b;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-qt-cancel:hover {
  background-color: #b30015;
}
