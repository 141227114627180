.checklist-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
}

.checklist-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.checklist-container ul {
  list-style-type: none;
  padding: 0;
}

.checklist-container li {
  margin-bottom: 15px;
  font-size: 18px;
}

.go-to-home-btn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.go-to-home-btn:hover {
  background-color: #218838;
}
