/* Container for the favorites section */

.favorite-items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Scope the styling to only affect item-boxes inside the favorites component */
.favorite-items-container .item-box {
  width: calc(45% - 20px); /* Adjust for 3 boxes per row with gaps */
  margin-bottom: 20px; /* Space between rows */
  max-width: 300px; /* Set a max width to avoid too large boxes */
}

@media only screen and (max-width: 1200px) {
  .favorite-items-container .item-box {
    width: calc(50% - 20px); /* Adjust for 2 boxes per row on medium screens */
  }
}

@media only screen and (max-width: 768px) {
  .favorite-items-container .item-box {
    width: calc(100% - 20px); /* Full width for smaller screens */
  }
}

@media only screen and (max-width: 480px) {
  .favorite-items-container .item-box {
    width: 100%; /* Full width for mobile */
    margin-bottom: 15px;
  }
}
