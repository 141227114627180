/* UserOrders.css */
.user-orders-layout {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.user-tabs {
  display: flex;
  justify-content: center;
}

.user-tab {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  margin-right: 20px;
  transition: border-bottom 0.3s ease;
}

.user-tab.active {
  border-bottom: 2px solid black;
}

.user-orders-section {
  padding: 20px;
}

.user-order-box {
  background-color: #d0e7e3;
  /* background-color: white; */
  padding: 15px;
  border: 5px solid black; /* Adds a thick black border */
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  min-width: 300px;
  box-sizing: border-box;
}

.user-order-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 2px solid black;
}

.user-event-date,
.user-order-type,
.user-total-saving,
.user-total-rental-price {
  margin: 5px;
  font-size: 16px;
}

.user-order-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.user-item-details {
  display: flex;
  margin-bottom: 10px;
  /* width: 100%; */
  max-width: 800px;
  border-bottom: 2px solid black;
  padding-bottom: 10px;
  justify-content: center; /* Centers the items horizontally */
}

.user-item-image {
  width: 170px;
  height: 170px;
  object-fit: cover;
  margin-right: 20px;
}

.user-item-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* justify-content: center; */
}

.user-item-info p {
  margin: 5px 0;
}

.user-total-savings {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.user-show-more-button-container {
  display: flex;
  justify-content: center; /* Align the buttons horizontally in the center */
  width: 100%; /* Make sure it takes up the full width of the order box */
  margin-top: 20px; /* Space above the button */
  position: relative; /* Ensure we can position the button exactly in the middle */
}

.user-show-more-button {
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.user-show-more-button:hover {
  background-color: #3e5455;
}
/* Responsive */
@media (max-width: 768px) {
  .user-order-box {
    flex-direction: column;
  }

  .user-order-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .user-item-details {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .user-item-image {
    margin-bottom: 20px;
  }
}
