.categories-container {
  margin: 20px;
  text-align: center;
}

.categories-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the items horizontally */
  gap: 20px;
  padding: 0;
  list-style: none; /* Remove bullets */
}

.category-item-button {
  width: 150px;
  height: 100px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.category-item-button:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

.category-item-button:active {
  background-color: #d0d0d0;
  transform: scale(0.95);
}
