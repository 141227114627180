/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 100%;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.add-discount-button {
  background-color: #28a745;
  color: white;
  font-size: 20px;
  border: none;
  padding: 10px 16px;
  cursor: pointer;
}

.add-discount-button:hover::after {
  content: attr(title);
  position: absolute;
  background-color: #555;
  color: white;
  padding: 5px;
  border-radius: 5px;
  top: -40px;
  left: 0;
  font-size: 14px;
}

.supercategory-item-content,
.category-item-content {
  display: flex;
  flex-direction: column; /* Ensure the + button is below the main button */
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: 150px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}
