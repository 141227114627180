.terms-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
}

.terms-container h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.terms-container p {
  margin-bottom: 15px;
}

.terms-container h3 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.terms-container strong {
  font-weight: bold;
}
