.categories-container {
  text-align: center;
}

.categories-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adjust gap between boxes */
  margin-top: 20px;
}

.category-box {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  border: 2px solid black; /* Adds a border around the box */
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.category-box:hover {
  background-color: #7a9a9b;
}
