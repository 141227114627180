.payment-page-container {
  display: flex;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  font-family: "Arial", sans-serif;
  padding: 40px;
  flex-direction: column; /* Ensure items stack vertically */
}

.payment-form {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.payment-form h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.payment-form button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-form button:disabled {
  background-color: #b0d0ff;
  cursor: not-allowed;
}

.payment-form button:hover:enabled {
  background-color: #0056b3;
}

.card-element {
  margin: 20px 0;
}
