.supercategories-container {
  text-align: center;
}

.supercategories-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adjust gap between boxes */
  margin-top: 20px;
}

.supercategory-box {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  border: 2px solid black; /* Adds a border around the box */
  width: 150px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  margin-top: 10px;
}

.supercategory-box:hover {
  background-color: #7a9a9b;
}

.supercategory-box-image {
  width: 100%; /* Full width for uniform size */
  height: 100px; /* Fixed height for all images */
  border-radius: 5px; /* Add some rounding */
}

.supercategory-name {
  text-align: center;
}

.category-image {
  width: 60%;
  height: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 8px;
}
