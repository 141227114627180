.items-list-discount-container {
  margin: 20px;
  text-align: center;
}

.items-list-discount {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the items horizontally */
  gap: 20px;
  padding: 0;
  list-style: none; /* Remove bullets */
}

.item-discount {
  width: 250px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: background-color 0.3s, transform 0.3s;
}

.item-discount strong {
  display: block;
  margin-bottom: 10px;
}

.item-discount p {
  margin: 5px 0;
}

.item-discount-button {
  margin-top: 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.item-discount-button:hover {
  background-color: #e60000;
}

.item-discount-button:active {
  background-color: #cc0000;
  transform: scale(0.95);
}

.item-discount ul {
  padding-left: 0;
  list-style: none;
}

.item-discount ul li {
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-discount ul li button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.item-discount ul li button:hover {
  background-color: #e60000;
}

.item-discount ul li button:active {
  background-color: #cc0000;
  transform: scale(0.95);
}
