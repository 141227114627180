@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Montserrat:wght@400;700&family=Roboto:wght@400;700&display=swap");

.total-box {
  background-color: #005a70; /* A darker shade of blue */
  padding: 15px;
  border-radius: 0px; /* Square corners */
  margin: 20px auto; /* Center the box */
  color: white;
  font-size: 18px;
  width: 300px; /* Set a fixed width */
  height: auto; /* Adjust height dynamically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.strikethrough {
  text-decoration: line-through;
  color: #777; /* Grey color for the original price to indicate it's not the final price */
  margin-right: 8px; /* Space between the original and final prices */
}

.final-price {
  color: #000; /* Black color for emphasis on the final price */
  font-weight: bold; /* Make the final price bold */
}

.cart-item {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 0.5px solid gray; /* Black border as per design */
  background-color: #fff; /* White background */
  margin-bottom: 20px;
  width: 70%;
  margin-left: auto; /* Center align start */
  margin-right: auto; /* Center align end */
  overflow: hidden; /* Prevent overflow */
  height: auto; /* Adjust height dynamically to fit content */
}

.cart-item-image {
  width: 150px; /* Adjust image size */
  height: 150px; /* Adjust image size */
  object-fit: cover; /* Ensure image covers the area */
  border-radius: 0px; /* Square corners */
}

.cart-item-details {
  flex: 1;
  padding: 0 10px; /* Add padding around text */
  display: flex;
  flex-direction: column; /* Stack the text vertically */
  justify-content: space-between; /* Distribute space evenly */
}

.order-list-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.cart-items-container {
  flex: 3;
  padding-right: 20px; /* Space between the columns */
}

.checkout-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the contents */
}

.cart-header {
  margin-bottom: 20px;
  margin-left: 130px;
}

@media (max-width: 1062px) {
  .order-list-container {
    flex-direction: column;
    align-items: center; /* Center items horizontally */
  }

  .checkout-container {
    display: flex; /* Ensure this is visible and arranged as a column */
    width: 100%; /* Use the full width */
    order: 2; /* Position it below the items */
  }

  .cart-item {
    width: 100%; /* Make the item container full width */
    flex-direction: column; /* Stack vertically within the cart item */
    align-items: center; /* Center align the contents */
  }

  .cart-item-image {
    margin-bottom: 10px; /* Space between image and text */
  }

  .cart-header {
    margin-left: 0px;
  }
}
