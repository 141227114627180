.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.auth-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 5px;
}

.auth-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.auth-input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.auth-button {
  width: 95%;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 25px;
}

.auth-button:hover {
  background-color: #0056b3;
}

.forgot-password-link {
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
  margin-left: auto;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

/* Dialog Overlay */
.dialog-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark translucent background */
  backdrop-filter: blur(5px); /* Subtle blur effect */
}

/* Dialog Content */
.dialog-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Dialog Title */
.dialog-title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

/* Dialog Description */
.dialog-description {
  font-size: 16px;
  text-align: center;
  color: #666;
}

/* Input Field */
.dialog-input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

.dialog-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Button Container */
.dialog-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* Buttons */
.dialog-button {
  flex: 1;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  transition: background 0.3s ease-in-out, transform 0.1s;
}

/* Confirm Button */
.dialog-button.confirm {
  background-color: #007bff;
  color: white;
}

.dialog-button.confirm:hover {
  background-color: #0056b3;
}

.dialog-button.confirm:active {
  transform: scale(0.98);
}

/* Cancel Button */
.dialog-button.cancel {
  background-color: #ccc;
  color: black;
  margin-left: 10px;
}

.dialog-button.cancel:hover {
  background-color: #bbb;
}

.dialog-button.cancel:active {
  transform: scale(0.98);
}
