/* iPhone SE, 6/7/8, X and newer (320px to 414px) */
@media only screen and (min-device-width: 320px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  /* Hide Fulcrum Group and Abbey Party Rents links */
  .fulcrum-link,
  .abbey-link {
    display: none;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3) {
  /* Hide Fulcrum Group and Abbey Party Rents links */
  .fulcrum-link,
  .abbey-link {
    display: none;
  }
}
