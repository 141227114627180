/* ItemPage.css */
.item-page {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  padding: 20px;
}

.item-details {
  flex: 1; /* Item details take the remaining space */
  margin-right: 10px;
}

.item-image {
  flex-shrink: 0; /* Prevent the image from shrinking */
  width: 550px; /* Increased the image width to make it bigger */
  height: 500px;
  border-radius: 8px; /* Optional, to give rounded corners */
  margin-top: 30px;
  margin-right: 30px;
}

.item-details button {
  margin-top: 10px; /* Space between buttons and other elements */
}

.total-savings-box {
  font-size: 1.5em; /* Increase font size for emphasis */
  font-weight: bold;
  color: #fff; /* White text for contrast */
  background-color: #005a70;
  padding: 10px 20px;
  margin-bottom: 10px; /* Space between this box and other elements */
  display: inline-block; /* To adjust box size according to content */
}

/* Specific styling for the "ADD TO ORDER" button */
.add-to-order {
  font-size: 18px; /* Make it bigger */
  padding: 15px 30px; /* Make the padding bigger */
  border-radius: 15px; /* Optional: Change border radius to make it more prominent */
}

/* Specific styling for the "BACK TO CATEGORY" button */
.back-to-category {
  background-color: #3e5455; /* Darker color */
  font-size: 14px; /* Make it smaller */
  padding: 8px 10px; /* Smaller padding */
  border-radius: 8px; /* Optional: Change border radius to make it different */
}

/* iPhone SE, 6/7/8, X and newer (320px to 414px) */
@media only screen and (min-device-width: 320px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .item-page {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }

  .item-image {
    width: 250px; /* Smaller width for mobile screens */
    margin-right: 0; /* Remove margin to the right */
    margin-bottom: 20px; /* Add space between image and details */
  }

  .item-details {
    margin-right: 0; /* Remove margin for mobile layout */
    text-align: center; /* Center text for smaller screens */
  }
}

/* Larger mobile screens (414px to 896px) */
@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3) {
  .item-page {
    flex-direction: column;
    align-items: center;
  }

  .item-image {
    width: 300px; /* Slightly larger width for larger mobile screens */
    margin-right: 0;
    margin-bottom: 20px;
  }

  .item-details {
    margin-right: 0;
    text-align: center;
  }
}
