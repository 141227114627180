.howItWorksContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Changed from center to flex-start */
  align-items: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 30px; /* Added more padding at the top to push everything down */
  margin-bottom: 70px;
}

.centeredList {
  list-style-position: inside;
  text-align: left;
  padding: 0;
  margin: 20px 0;
  width: 100%;
  max-width: 700px;
}

.centeredList li {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.6;
}

button:hover {
  background-color: #459cb6; /* Darker shade of blue on hover */
}

.how-it-works-image {
  flex-shrink: 0; /* Prevent the image from shrinking */
  width: 800px; /* Increased the image width to make it bigger */
  height: auto;
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional, to give rounded corners */
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 20px;
}

/* iPhone SE, 6/7/8, X and newer (320px to 414px) */
@media only screen and (min-device-width: 320px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .how-it-works-image {
    width: 200px;
  }

  .howItWorksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 30px; /* Added more padding at the top to push everything down */
    margin-bottom: 40px;
  }
}

/* Larger mobile screens (414px to 896px) */
@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3) {
  .how-it-works-image {
    width: 300px;
  }

  .howItWorksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 30px; /* Added more padding at the top to push everything down */
    margin-bottom: 40px;
  }
}
