/* Admin Orders Container */
.admin-orders-container {
  width: 100%;
  padding: 30px;
  background-color: #f5f7fa;
  display: flex;
  justify-content: center;
}

.admin-totals {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align to the left */
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  /* margin-bottom: 10px; */
}

.admin-totals p {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0; /* Add spacing between the lines */
}

/* Radix Tabs Container */
.admin-tabs-container {
  width: 100%;
  max-width: 1400px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Tab Navigation */
.admin-tabs-list {
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
  background-color: #f1f3f5;
  border-radius: 8px;
}

.admin-tab-trigger {
  padding: 12px 20px;
  background-color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}

.admin-tab-trigger:hover {
  background-color: #e3e7eb;
}

.admin-tab-trigger[data-state="active"] {
  background-color: black;
  color: white;
  font-weight: bold;
  border-radius: 8px;
}

/* Shared Table Styling (OpenTable & ClosedTable) */

.no-quotes {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  color: #888;
}

.admin-table-container {
  width: 100%;
  overflow-x: auto;
  padding: 20px;
  margin-top: 15px;
}

/* Table Styling */
.admin-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  font-size: 16px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

/* Table Header */
.admin-table thead {
  background-color: #f8f9fa;
}

.admin-table th {
  padding: 16px;
  text-align: left;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
}

/* Table Rows */
.admin-table td {
  padding: 14px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.admin-table tr:hover {
  background-color: #f1f1f1;
}

/* Buttons */
.admin-btn {
  padding: 8px 14px;
  border: none;
  cursor: pointer;
  font-size: 15px;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
}

.admin-btn.confirm {
  background-color: #28a745;
  color: white;
}

.admin-btn.unconfirm {
  background-color: #dc3545;
  color: white;
}

.admin-btn.download {
  background-color: #007bff;
  color: white;
}

.admin-btn:hover {
  opacity: 0.85;
}

.admin-checkmark {
  font-size: 24px; /* Make it bigger */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* Pagination Controls */
.admin-pagination {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 10px;
}

.admin-pagination button {
  padding: 10px 14px;
  border: 2px solid #ddd;
  background-color: white;
  cursor: pointer;
  font-size: 15px;
  border-radius: 5px;
}

.admin-pagination button:hover {
  background-color: #e9ecef;
}

.admin-pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sortable-header {
  cursor: pointer;
}
