.items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Ensures even spacing and alignment */
  gap: 20px; /* Increase gap for better spacing between items */
  padding: 20px; /* Adds padding around the container */
}

.button-container {
  display: flex;
  justify-content: center; /* Center-align the button */
  margin: 20px 0; /* Add margin above and below the button for spacing */
}

.ItemBox h3,
.ItemBox p {
  margin: 5px 0; /* Adds vertical spacing between text elements */
}

.search-bar {
  margin: 20px 0;
  text-align: center;
}

.search-input {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Styles for the dialog overlay (dimmed background) */
.dialog-overlay {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  position: fixed;
  inset: 0; /* Cover the entire screen */
  z-index: 1000; /* Ensure it's above everything */
}

/* Styles for the dialog content box */
.dialog-content {
  background: #fff; /* White background for the dialog */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); /* Shadow effect for depth */
  width: 90%; /* Responsive width */
  max-width: 400px; /* Limit max width for better readability */
  padding: 30px; /* Padding inside the dialog */
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Align center perfectly */
  z-index: 1001; /* Above overlay */
  text-align: center;
  font-family: "Montserrat", sans-serif; /* Modern font */
}

/* Dialog Title Styling */
.dialog-content h2,
.dialog-content .dialog-title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

/* Dialog Description Styling */
.dialog-content .dialog-description {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Login/Signup link styling */
.dialog-content .dialog-description span {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
}

.dialog-content .dialog-description span:hover {
  text-decoration: underline;
}

/* Close button styling */
.close-button {
  background-color: #a4b6b6; /* Blue Agave color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 100%; /* Make button full-width */
}

.close-button:hover {
  background-color: #0056b3;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .dialog-content {
    width: 90%;
    max-width: 90%;
  }

  .search-input {
    width: 90%;
  }
}
