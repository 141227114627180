.order-form-modal {
  background-color: #3e5455;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 500px; /* Adjusted width */
  max-height: 870px;
  overflow-y: auto; /* Allows scrolling */
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

.order-form-modal form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.order-form-modal input,
.order-form-modal select,
.order-form-modal textarea {
  width: 75%; /* Ensures inputs take full width */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.multi-input-container {
  /* New container for dates */
  display: grid;
  grid-template-columns: 4fr 3fr 2fr; /* Split equally */
  gap: 5px;
}
.date-input-container {
  /* New container for dates */
  display: grid;
  grid-template-columns: 1fr 1fr; /* Split equally */
  gap: 5px;
}

.order-form-modal label {
  font-weight: bold;
  display: block; /* Makes label take its own line */
  margin-bottom: 5px;
}

.order-form-actions {
  display: flex;
  justify-content: center; /* Center content horizontally */
  margin-top: 20px;
}

/* iPhone SE, 6/7/8, X and newer (320px to 414px) */
@media only screen and (min-device-width: 320px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .order-form-modal {
    width: 90%; /* Make form width smaller for iPhones */
    padding: 15px;
    max-height: 100%; /* Use full height on smaller screens */
  }

  .order-form-modal input,
  .order-form-modal select,
  .order-form-modal textarea {
    width: 100%; /* Full width for inputs */
    font-size: 14px; /* Slightly smaller font size */
  }

  .multi-input-container,
  .date-input-container {
    grid-template-columns: 1fr 1fr; /* Simplify grid for small screens */
  }

  .order-form-actions {
    flex-direction: column; /* Stack buttons on top of each other */
    gap: 10px;
  }
}

/* Larger mobile screens (414px to 896px) */
@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3) {
  .order-form-modal {
    width: 80%; /* Slightly larger form width for bigger phones */
    padding: 18px;
  }

  .order-form-modal input,
  .order-form-modal select,
  .order-form-modal textarea {
    width: 90%; /* Slightly smaller width */
    font-size: 15px;
  }

  .multi-input-container,
  .date-input-container {
    grid-template-columns: 1fr 1fr;
  }

  .order-form-actions {
    flex-direction: row; /* Keep buttons side-by-side */
    gap: 15px;
  }
}
