.calendar-image {
  flex-shrink: 0; /* Prevent the image from shrinking */
  width: 800px; /* Fixed width */
  height: auto;
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 8px;
  margin-top: 30px;
  margin-right: 30px;
}

.bubbles-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 100px;
  gap: 20px;
}

.bubble-item {
  background-color: #d0e7e3; /* Lighter bubble-like color */
  color: black;
  padding: 20px 40px; /* Equal padding for the text */
  border-radius: 50%; /* Make the item circular */
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the text horizontally and vertically */
  min-width: 120px; /* Ensure the bubble is big enough */
  max-width: 120px;
  height: 120px; /* Equal height to make it a perfect circle */
  transition: background-color 0.3s ease;
}

.bubble-item:hover {
  background-color: #8b9f9f;
}

.disabled-btn {
  background-color: #ccc; /* Gray */
  color: #666;
  border: none;
  cursor: not-allowed;
  border-radius: 5px;
}

.rdp {
  max-width: 400px; /* Maximum width of the calendar */
  margin: 0 auto; /* Centering the calendar horizontally */
  background-color: #3e5455; /* Black background for calendar */
  color: #fff; /* White text  */
}

.rdp-months {
  margin-left: 60px;
}

.rdp-tfoot {
  font-size: 15px;
}

/* iPhone SE, 6/7/8, X and newer (320px to 414px) */
@media only screen and (min-device-width: 320px) and (max-device-width: 414px) and (-webkit-min-device-pixel-ratio: 2) {
  .rdp {
    max-width: 300px; /* Maximum width of the calendar */
    margin: 0 auto; /* Centering the calendar horizontally */
  }
  .calendar-image {
    flex-shrink: 0; /* Prevent the image from shrinking */
    width: 300px; /* Fixed width */
    height: auto;
    object-fit: cover; /* Maintain aspect ratio */
    border-radius: 8px;
    margin-top: 30px;
    margin-right: 30px;
  }

  .rdp-months {
    margin-left: 20px;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3) {
  .rdp {
    max-width: 300px; /* Maximum width of the calendar */
    margin: 0 auto; /* Centering the calendar horizontally */
  }
  .calendar-image {
    flex-shrink: 0; /* Prevent the image from shrinking */
    width: 300px; /* Fixed width */
    height: auto;
    object-fit: cover; /* Maintain aspect ratio */
    border-radius: 8px;
    margin-top: 30px;
    margin-right: 30px;
  }
  .rdp-months {
    margin-left: 20px;
  }
}
